import { type MutableRefObject, useEffect, useRef, useState } from 'react';

import { initializePreferencesData } from '../../../../controllers/cookie-controls/transformer';
import { getPreferences } from '../../../../controllers/storage-preferences/get-preferences';
import { updatePreferences } from '../../../../controllers/storage-preferences/update-preferences';
import type { PrefetchedConsentPreferences } from '../../../../services/consent-hub-service/types';
import { type InitializedPrefsData } from '../../../../types';

export interface PreferencesHookProps {
	initialPreferences?: PrefetchedConsentPreferences | undefined;
}

export interface LoadStoragePreferencesProps {
	isInitialRender?: MutableRefObject<boolean>;
	setPreferencesData: (prefs: InitializedPrefsData) => void;
}

/**
 * This function is responsible for loading the user's preferences from storage, and setting the state accordingly.
 * If the user's preferences are not found in storage, the default preferences will be used.
 */
const loadStoredPreferences = async ({
	setPreferencesData: setPreferencesData,
	isInitialRender,
}: LoadStoragePreferencesProps) => {
	const prefs = await getPreferences();
	// If something has gone wrong during this `get` process (invalid token, failed request, etc.)
	// these preferences will be undefined and we'll need to show the banner as we're in an unknown state
	setPreferencesData({
		prefs,
		isPrefetchedPrefsInternalState: Boolean(prefs),
	});

	// Set the initialRender flag to false so we can skip logic in banner rendering
	if (isInitialRender) {
		isInitialRender.current = false;
	}
};

/**
 * This hook is responsible for handling any initially provided preference data, and/or fetching missing preference
 * data from ConsentHub. It will then set the appropriate cookies and state for the user's preferences to be used
 * throughout the application.

 * @param (optional) initialPreferences - Any initially provided preferences from consuming applications (used for SSR)
 * @returns (object) {
 * 	preferences - The current state of the user's preferences after fetching/transforming/setting cookies
 *	isPrefetchedPreferencesState - A boolean that indicates if we are in a prefetched internal state
 *	setPreferencesData - Setter function to update the preferences state
 *	isInitialRender - A boolean that indicates if this is the first render of the component
 * }
 */
const usePreferences = ({ initialPreferences }: PreferencesHookProps) => {
	/**
	 * isInitialRender is used to ensure consistent banner visibility by addressing potential mismatches that occur
	 *  during the initial render. Without this flag, the application might incorrectly display a banner based on
	 * incomplete or missing preference data. During the rehydration process, this initial render triggers
	 * the useMemo and useEffect hooks which means that initialPreferences may be undefined due to the asynchronous
	 * nature of setting props. This can lead to discrepancies in banner visibility between the SSR state
	 * and the client-rendered state.
	 */
	const isInitialRender = useRef(true);
	const prefetchedPreferencesData = useRef(initialPreferences);
	const [preferencesData, setPreferencesData] = useState<InitializedPrefsData>(
		initializePreferencesData(initialPreferences),
	);

	useEffect(() => {
		const { prefs: unpackedPrefetchedPrefs, isPrefetchedPrefsInternalState } =
			initializePreferencesData(prefetchedPreferencesData.current);

		if (!isPrefetchedPrefsInternalState && unpackedPrefetchedPrefs) {
			// With a valid set of prefs, set them on the cookies, but don't save to ConsentHub
			updatePreferences(unpackedPrefetchedPrefs, undefined, true);
		}
		// On initial render, this will either set the proper preferences in state + required cookies based on the
		// prefetched data, or fetch all of that data from ConsentHub and then set appropriate cookies + state.
		loadStoredPreferences({
			setPreferencesData,
			isInitialRender,
		});
	}, [isInitialRender]);

	return {
		preferences: preferencesData?.prefs,
		isPrefetchedPreferencesState: preferencesData?.isPrefetchedPrefsInternalState,
		setPreferencesData,
		isInitialRender: isInitialRender.current,
	};
};

export default usePreferences;
