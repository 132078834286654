import React, { useCallback, useEffect, useRef, useState } from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import {
	sendScreenEvent,
	sendTrackEvent,
	sendUIEvent,
} from '../../../common/utils/analytics-client';
import { Logger } from '../../../common/utils/logger';
import { ONLY_NECESSARY_PREFERENCES } from '../../../constants';
import { updatePreferences } from '../../../controllers/storage-preferences/update-preferences';
import type { ConsentDisplayedTextMap } from '../../../services/consent-hub-service/types';
import { type ConsentPreference } from '../../../types';

import { PresentationalPreferenceModal } from './presentational-preference-modal';
import usePreferences from './use-preferences';

interface PreferenceModalProps {
	/**
	 * Callback to run custom onClose logic when the preference modal is closed internally.
	 */
	onClose?: () => void;
	/**
	 * Callback to run custom onSubmit logic when preferences are submitted.
	 */
	onSubmit?: () => void;

	showSuccessFlag?: () => void;
	showErrorFlag?: () => void;
}

export const PreferenceModal = (props: PreferenceModalProps) => {
	// feature flag check
	if (!fg('platform.moonjelly.browser-storage-controls')) {
		return null;
	}

	return <PreferenceModalImpl {...props} />;
};

export const PreferenceModalImpl = ({
	onClose,
	onSubmit,
	showSuccessFlag,
	showErrorFlag,
}: PreferenceModalProps) => {
	const [isModalOpen, setIsModalOpen] = useState(true);

	const isEventSent = useRef(false);
	useEffect(() => {
		if (!isEventSent.current) {
			sendScreenEvent({ name: 'cookieConsentModal' });
			isEventSent.current = true;
		}
	}, [isEventSent]);

	const { preferences, setPreferencesData } = usePreferences({});

	// If we don't have preferences, set default preferences
	// for modal interactions
	if (!preferences) {
		setPreferencesData({
			prefs: ONLY_NECESSARY_PREFERENCES,
			isPrefetchedPrefsInternalState: false,
		});
	}

	const handleOnSubmit = useCallback(
		async (displayedTextMap: ConsentDisplayedTextMap) => {
			if (preferences) {
				const selectedPreferences: ConsentPreference = preferences;

				sendUIEvent({
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'confirm',
					source: 'cookieConsentModal',
				});

				try {
					await updatePreferences(selectedPreferences, displayedTextMap);

					sendTrackEvent({
						source: 'cookieConsentModal',
						action: 'submitted',
						actionSubject: 'cookieConsentPreferences',
					});

					showSuccessFlag?.();
				} catch (e: any) {
					Logger.errorWithOperationalEvent({
						action: 'updatePreferencesError',
						attributes: { preferences: selectedPreferences },
						message: `Failed to update preferences from modal. ${e.message || ''}`,
					});

					showErrorFlag?.();
				}

				setIsModalOpen(false);
				onSubmit?.();
			} else {
				// Preferences have reached an invalid state, show error flag
				showErrorFlag?.();
			}
		},
		[preferences, onSubmit, showSuccessFlag, showErrorFlag],
	);

	return (
		<>
			{isModalOpen && (
				<PresentationalPreferenceModal
					onSubmit={handleOnSubmit}
					onClose={onClose}
					setIsOpen={setIsModalOpen}
					preferences={preferences ?? ONLY_NECESSARY_PREFERENCES}
					setPreferencesData={setPreferencesData}
				/>
			)}
		</>
	);
};
